
.detail-banner{
    position: relative;
    padding-top:10px;
    height: 500px;
    /* background-image: url("../images/detail-banner.png"); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .detailevent-card{
    position: relative;
  }
  
  .navigate-p{
    color: #f43254;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  
  .attendees-btn{
    padding: 16px 20px;
    /* opacity: 0.6; */
    border-radius: 8px;
    background-color: #000;
  }
  
  .Attendees-text{
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: white;
  }
  
  .Attendees-1-100 .text-style-1 {
    font-weight: bold;
    color: #ffffff;
  }
  

  @media only screen and (max-width: 1800px){
    .detail-img-div{
      position: absolute;
      bottom: -210px;
      width: 270px;
      left:80px;
      border-radius: 8px;
      border: solid 5px #fff;
      background-color: #fff;
    }
  }

  @media only screen and (min-width: 1800px){
    .detail-img-div{
      position: absolute;
      bottom: -210px;
      width: 274px;
      left:300px;
      border-radius: 8px;
      border: solid 5px #fff;
      background-color: #fff;
    }
  }

  
  .cardimg1{
    border-radius: 8px;
    width: 274px;
    padding: 0px;
  }
  
  .detail-text-div{
    display: flex;
    flex-direction: column;
    gap:15px;
    padding-top: 30px;
    margin-left: 300px;
  }
  
  .event-pro-head{
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
  }
  
  .detail-p-text{
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
  }
  
  .t-paragraph{
    margin-top: 30px;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #333;
  }
  
  .t-paragraph1{
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #333;
  }
  
  
  .social-media-div{
    margin-top: 30px;
  }

  .social-back-img{
    height: 400px;
    background-image: url('../images/group.png');
    /* background-size: cover; */
    object-fit: contain;
    background-repeat: no-repeat;
    padding-right: 100px;
  }

  .vide0-div{
    margin-top: 30px;
  }
  
  .social-m-head{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
  }
  
  .linkedin-div{
    height: 74px;
    width: 74px;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #e9e9e9;
     border-radius: 100%;
  }
  
  .google-div{
    margin-top: 150px;
    height: 74px;
    width: 74px;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color:#ffffff;
     border-radius: 100%;
  }
  
  .rounded-ringus-img{
    height: 293px;
    width: 293px;
    background-image: url("../images/ringus-rounded-background.svg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .rounded-ringus-main{
    display: flex;
    justify-content: center;
    /* align-items: center;   */
  }
  
  .rounded-ringus-div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
    width: 200px;
    height: 200px;
    padding: 81px 29px 61px 29px;
    box-shadow: 0 0 21px 0 rgba(244, 50, 84, 0.55);
    background-color: #f43254;
    border-radius: 100%;
  }
  
  .event-video{
    border-radius: 8px;
  }
  
  .bottom-square-img{
    height: 185px;
    border-radius: 8px;
  }
  
  .bottom-verti-img{
    height: 395px;
    border-radius: 8px;
  }
  
  .packages-div{
    padding-top: 30px;
    height: 1200px;
    overflow: auto;
  }
  
  .package-card{
    width: 100%;
    padding: 16px 15px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #e9e9e9;
    background-color: #fff;
    margin-top: 10px;
    overflow: hidden;
    max-height: 60px;
    transition: max-height 0.5s ease-in-out;
  }

  
  .package-card.open {
    max-height: 500px; /* Set a value based on your content height */
  }

  
  .pack-card-heading{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
  }
  
  .pack-content-text{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
  }
  
  .get-it-btn{
    width: 100%;
    margin-top: 10px;
    padding: 15px 90px 14px 97.5px;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
    background-color: #f43254;
  }
  
  .pack-price-text{
    font-weight: bold;
    color: #09bf6a;
  }
  
  .pack-bold-text{
    font-weight: bold;
    color: #333333;
  }

  .card-drop-btn{
    width: 100%;
    background-color: white;
    border: none;
  }

  .event-gellary-img{
    border-radius: 8px;
    height: 190px;
    width: 180px;
    object-fit: cover;
  }

  
.pdf-div{
  width: 180px;
  height: 190px;
  padding: 27px 47px 27px 47px;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e9e9e9;
  background-color: #fff;
}

.hi{
  gap: 15px 35px
}
  
.pdf-title{
  margin: 16px 0 0;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333;
  text-overflow: ellipsis; 
  overflow: hidden;
  white-space: nowrap;
}

.custom-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal.show {
  display: flex;
}

.modal-dialog {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 400px;
}

.modal-content {
  padding: 20px;
  width: 521px;
  height: 592px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 10px 10px;
}

.modal-title {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
  margin: 0;
}

.modal-body {
  margin-bottom: 20px;
  padding: 10px;
}

.btn-close {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.modal-dis{
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}

.Character-0500{
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #818181;
}

.Character-0500 .text-style-1 {
  color: #333;
}



.send-Query-btn{
  padding: 14px 13px 13px;
  border-radius: 6px;
  background-color: #f43254;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: none;
}

.img-select-button{
  border: none;
  background-color: white;
}

/* r--------------------------------- */

.reviews-card{
  width: 370px;
  height: 348px;
  padding: 28px 28px 36px;
  border-radius: 6px;
  border: solid 1px #e1e6d5;
  background-color: #fff;
}

.reviews-img{
  height: 64px;
}

.reviews-name-text{
  margin: 0 0 4px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #333333;
}

.reviews-text{
  margin: 4px 56px 0 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #909090;
}

.reviews-paragraph{
  margin: 23px 0 13px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #333;
}
/* ------------ */
