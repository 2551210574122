
.event-card-div{
    /* padding-top: 30px; */
    padding-bottom: 30px;
  }
  
  .your-events{
    padding-top: 30px;
    padding-bottom: 50px;
  }
  
  .your-events-heading{
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
  }
  
  .your-event-content{
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
  
  .yourevent-content-div{
    height: 500px;
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .emptyevent-image{
    height:200px;
  }
  
  .Looks-like-you-haven{
    margin-top: 20px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #888;
  }
  
  .Create-new-Event{
    /* margin-top: 20px; */
    display: flex;
    justify-content: center;
    gap:15px;
    padding: 14px 40px;
    border-radius: 8px;
    background-color: #f43254;
    color: white;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
  }
  
  .Create-new-Event:hover{
    color: white;
    background-color: #f43254;
  }
 

  .select-myevent-tab{
    background-color: white;
    border:none;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.28px;
    color: #333; 
  }

  .myevent-tab{  
    background-color: white;
    border:none;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.28px;
    color: #b6b6b6;
  }

  .tab-bottom-line{
    width: 45px;
    height: 6px;
    margin: 8px 0px 0 0px;
    background-color: #f43254;
  }
 
  /* My event  */

.myevent-card-img {
  position: relative;
  width: 284px;
  height: 300px;
  border-radius: 8px;
  object-fit: cover;
}

.myevent-rectangle {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0px;
  width: 151px;
  height: 36px;
  padding: 8px 10px 8px 10px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #f43254;
  color: white;
}

  .edit-event-btn {
    background-color: white;
    color: black;
    border: none;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f43254;
  }

  .event-info-div{
    position: absolute;
    z-index: 10;
    right:30px;
    top: 20px;
    width: 74px;
    height: 20px;
    padding: 2px 5px;
    border-radius: 2px;
    background-color: #61a351;
    font-family: Poppins;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }