
.signup-area {
    height: 100vh;
    /* position: relative; */
    z-index: 100000;
    background-image: url("../images/signin-background-image.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    
  }
  
  .signup-area .signup-form {
    /* width: 872px; */
    height: 750px;
    /* opacity: .77; */
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(8px);
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.11);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 10px;
    margin: 0 auto;
    padding: 40px 60px 70px;
  }
  
  
  /* .signup-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #FF2D55;
    opacity: .77;
  } */
  
  
  
  .signup-area .signup-form h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    
  }
  
  .signup-area .signup-form form {
    padding: 35px;
  }
  
  .signup-area .signup-form form label {
    font-weight: 500;
  }
  
  .signup-area .signup-form form .form-control {
    background: transparent;
    font-size: 13px;
  }
  
  .signup-area .signup-form .btn {
    display: block;
    width: 100%;
    margin-top: 20px;
  }
  
  .signup-area .signup-form p {
    /* text-align: center; */
    /* color: white; */
    margin-bottom: 0;
    margin-top: 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    /* color: #fff; */
  }
  
  .signup-area .signup-form p a {
    /* color: #0d1028; */
    display: inline-block;
    font-weight: 600;
  }
  
  .signup-area .signup-form p a:hover, .signup-area .signup-form p a:focus {
    color: #FF2D55;
  }