


@media only screen and (max-width: 600px){
  .logo-img{
    height: 18px;
  }
  
  .signup-btn{
    border: none;
    padding: 7px 12px;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
    background-color: #f43254;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
  }

  .signin-btn{
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
  }
  .elkevent-home-nav nav ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
}

@media only screen and (max-width: 1600px){
  .logo-img{
    height: 25px;
  } 
  
  .signup-btn{
    border: none;
    padding: 10px 27px 10px 26px;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
    background-color: #f43254;
    color: white;
    font-weight: 500;
  }

  .signin-btn{
    font-family: Poppins;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
  }

  .elkevent-home-nav nav ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
}

@media only screen and (min-width: 1600px){
  .logo-img{
    height: 30px;
  } 
  
  .signup-btn{
    border: none;
    padding: 10px 27px 10px 26px;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
    background-color: #f43254;
    color: white;
    font-weight: 500;
  }

  .signin-btn{
    font-family: Poppins;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
  }

  .elkevent-home-nav nav ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
}

.navbar-toggler{
  padding: 0;
}

.navbar-light .navbar-toggler {
  color: #22252d;
  border: none;
}

.navbar-light .navbar-toggler-icon {
  background-size: 20px;
  background-image: url("../images/toggler-icon.png");
}

.elkevent-mobile-nav {
    display: none;
  }
  
  .elkevent-mobile-nav.mean-container .mean-nav ul li a.active {
    color: #FF2D55;
  }
  
  .elkevent-home-nav {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    padding: 14px 0px;
    width: 100%;
    z-index: 999;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
    height: auto;
  }
  
  
  .elkevent-nav nav ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  
 

  .login-signup-div{
      display: flex ;
  }
  
  .elkevent-home-nav nav .navbar-nav .nav-item {
    position: relative;
    padding: 0px 0;
  }
  
  .elkevent-nav nav .navbar-nav .nav-item {
    position: relative;
    padding: 0px 0;
  }
  
  /* .elkevent-nav nav .navbar-nav .nav-item a {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
  } */
/*   
  .elkevent-nav nav .navbar-nav .nav-item a:hover, .elkevent-nav nav .navbar-nav .nav-item a:focus, .elkevent-nav nav .navbar-nav .nav-item a.active {
    color: #FF2D55;
  }
   */
  /* .elkevent-nav nav .navbar-nav .nav-item:hover a {
    color: #FF2D55;
  } */
  
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu {
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
    background: #0d1028;
    position: absolute;
    top: 80px;
    left: 0;
    width: 250px;
    z-index: 99;
    display: block;
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 20px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li {
    position: relative;
    padding: 0;
  }
  
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a {
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 9px 15px;
    margin: 0;
    display: block;
    color: #ffffff;
  }
  
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover, .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a:focus, .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
    color: #FF2D55;
  }
  
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    position: absolute;
    left: 220px;
    top: 0;
    opacity: 0 !important;
    visibility: hidden !important;
  }
  
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
    color: #ffffff;
    text-transform: capitalize;
  }
  
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
    color: #FF2D55;
  }
  
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
    opacity: 1 !important;
    visibility: visible !important;
    top: -20px !important;
  }
  
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li:hover a {
    color: #FF2D55;
  }
  
  .elkevent-nav nav .navbar-nav .nav-item:hover ul {
    opacity: 1;
    visibility: visible;
    top: 100%;
  }
  

  @media only screen and (max-width: 600px){
  .other-option-div{
    width: 100%;
  }
  }

  

  .elkevent-nav nav .others-option {
    margin-left: 13px;
  }

  
/* search input */

@media only screen and (max-width: 600px){
  .elkevent-nav{
    background-color:#22252d;
    position: sticky;
    top: 0;
    left: 0;
    padding: 8px 0px;
    width: 100%;
    z-index: 999;
    height: auto;
  }

  .elkevent-nav.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px 0;
    z-index: 999;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: #22252d!important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }

  .elkevent-home-nav.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px 0;
    z-index: 999;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: #22252d!important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }

  .search-input-maindiv{
    width: auto
}

.main-input{
  margin-top: 15px;
  position: relative;
  display: flex;
  align-items: center;
  /* margin-left: 30px; */
  padding-right: 10px;
  background-color: #e4e4e4;
  border-radius: 5px;
  border-left: none;
}

.search-input{
  /* font-size: 12x; */
  width: 165px;
  background-color:#e4e4e4;
  border-top-left-radius: 10px;
  border-bottom-left-radius:10px ;
  border-right: none;
  padding: 10px 20px 10px 40px;
  border: none;
}

.location-input{
  width: 140px;
  background-color:#e4e4e4;
  border-top-right-radius: 10px;
  border-bottom-right-radius:10px ;
  border-left: none;
  margin-left: 28px;
  padding: 10px 20px 10px 10px;
  border: none;
}

.location-img{
 
  /* top:15px; */
  height:20px;
}
.ul-list{
  display: flex;
 align-items: start;
 gap:20px;
 margin-left: 20px !important;
}

}

@media (min-width: 600px) and (max-width: 1600px){
  .elkevent-nav{
    background-color:#22252d;
    position: sticky;
    top: 0;
    left: 0;
    padding: 12px 0px;
    width: 100%;
    z-index: 999;
    height: auto;
  }
  .elkevent-nav.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px 0;
    z-index: 999;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: #22252d!important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }
  .elkevent-home-nav.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px 0;
    z-index: 999;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: #22252d!important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }

  .search-input-maindiv{
    width: 268px;
  }
  .main-input{
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding-right: 10px;
    background-color: #e4e4e4;
    border-radius: 10px;
    border-left: none;
  }

  .search-input{
    /* font-size: 14px; */
    width: 120px;
    background-color:#e4e4e4;
    border-top-left-radius: 10px;
    border-bottom-left-radius:10px ;
    border-right: none;
    padding: 8px 5px 8px 40px;
    border: none;
  }

  .search-input:focus{
  border: #f43254;
  }

  .search-input:active{
    border: #f43254;
    }

  .location-input{
    width: 120px;
    background-color:#e4e4e4;
    border-top-right-radius: 10px;
    border-bottom-right-radius:10px ;
    border-left: none;
    padding: 8px 6px 7px 0px;
    border: none;
    text-overflow: ellipsis; 
    overflow: hidden;
    white-space: nowrap;
  }

 .location-text{
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}
 }

 @media only screen and (min-width: 1600px){
  .elkevent-nav{
    background-color:#22252d;
    position: sticky;
    top: 0;
    left: 0;
    padding: 12px 0px;
    width: 100%;
    z-index: 999;
    height: auto;
  }
  .elkevent-nav.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px 0;
    z-index: 999;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: #22252d!important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }
  .elkevent-home-nav.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px 0;
    z-index: 999;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: #22252d!important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }

  .search-input-maindiv{
    width: 400px;
  }
  .main-input{
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 30px;
    padding-right: 10px;
    background-color: #e4e4e4;
    border-radius: 10px;
    border-left: none;
  }

  .search-input{
    font-size: 14px;
    width: 174px;
    background-color:#e4e4e4;
    border-top-left-radius: 10px;
    border-bottom-left-radius:10px ;
    border-right: none;
    padding: 10px 5px 10px 40px;
    border: none;
  }

  .search-input:focus{
  border: #f43254;
  }

  .search-input:active{
    border: #f43254;
    }

  .location-input{
    width: 160px;
    background-color:#e4e4e4;
    border-top-right-radius: 10px;
    border-bottom-right-radius:10px ;
    border-left: none;
    padding: 8px 6px 7px 0px;
    border: none;
    text-overflow: ellipsis; 
    overflow: hidden;
    white-space: nowrap;
  }

 .location-text{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}
 }


  .location-img{
    /* top:15px; */
    height:20px;
  }

  .ul-list{
    display: flex;
   align-items: center;
   gap:20px;
   margin-left: 18px !important;
  }

  .search-img{
    position: absolute;
    height: 20px;
    left:12px;
  }
  

  /* .language-currency-div{
    display: flex;
    align-items: center;
    gap:20px;
    margin-left: 10px;
  } */

 

  .Upgrade-btn{
    padding: 10px 16.5px 10px 16px;
    border-radius: 6px;
    border: none;
    background-color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f43254;
  }

  @media only screen and (max-width: 1800px){
    .nav-btn{
      font-family: Poppins;
      /* font-size: 14px; */
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      background: 0 0;
      border: 0;
    }

    .currency-select-button{
      padding: 11px 16px 10px;
      border-radius: 8px;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: #000;
      font-family: Poppins;
      /* font-size: 14px; */
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
      border: none;
    }
  }

  @media only screen and (min-width: 1800px){
    .nav-btn{
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      background: 0 0;
      border: 0;
    }

    .currency-select-button{
      padding: 11px 16px 10px;
      border-radius: 8px;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: #000;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
      border: none;
    }
  }

  

  .avatar-dropdown-menu{
    position: absolute;
    z-index: 10;
    display: none;
    top:56px;
    right: -10px;
    /* width: 160px; */
    padding: 24px 33px 24px 24px;
    border-radius: 4px;
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 4px 8px 0 rgba(92, 92, 92, 0.2), 0 6px 20px 0 rgba(110, 110, 110, 0.19);
  }

  .avatar-dropdown-item{
  display: block;
  width: 100%;
  padding: 10px 20px;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  }

  .avatar-dropdown-item:hover{
      background-color: rgb(230, 230, 230);
  }

  .avatar-toggle-btn{
    border: solid 1px #f43254;
  }

  .user_info{
    width: 100%;
    height: 50px;
    background-color:#f43254;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .user_name_text{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background: 0 0;
    border: 0;
  }