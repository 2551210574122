.forgotpassword-area .forgotpassword-form {
    width: 480px;
    height: auto;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.11);
    /* opacity: .77; */
    background:rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 10px;
    margin: 0 auto;
    padding: 40px 60px 70px;
  }

  .forgotpassword-area {
    height: 100vh;
    position: relative;
    z-index: 100000;
    background-image: url("../images/signin-background-image.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    
  }
  
  .or-div{
    width: 35px;
    padding: 7px 6px 7px 7px;
    border-radius: 5px;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);  
    background-color: #6d6d6d;
    top: 130px;
  }
  
  .or-text{
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.47px;
    color: #fff;
  }
  
  .forgotpassword-area .forgotpassword-form h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  
  .forgotpassword-p{
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left!important;
  }
  
  .pad-x-35{
    padding: 0px 35px;
  }
  
  .Dont-have-an-accoun{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.47px;
  }
  
  .Dont-have-an-accoun-signup{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.47px;
    padding-right: 60px;
    /* color: #fff; */
  }
  
  
  .text-style-1{
    font-weight: 600;
    color: #f43254;
  }
  
  .forgotpassword-with-facebook{
    display: flex;
    align-items: center;
    justify-content:left;
    gap: 10px;
     width: 270px;
     padding: 10px 20px 10px 20px;
    border-radius: 8px;
    background-color: #0866ff;
    color: white;
    border: none;
  }
  
  .forgotpassword-with-linkedin{
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
     width: 270px;
     padding: 10px 20px 10px 20px;
    border-radius: 8px;
    background-color: #2d6bb4;
    color: white;
    border: none;
  }
  
  .forgotpassword-with-google{
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
     width: 270px;
     padding: 10px 20px 10px 20px;
    border-radius: 8px;
    background-color: #db4437;
    color: white;
    border: none;
  }
  
  .forgotpassword-with-instagram{
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
     width: 270px;
     padding: 10px 20px 10px 20px;
    border-radius: 8px;
    background-color: #933c6f;
    color: white;
    border: none;
  }
  
  
  .forgotpassword-btn-icon{
    height: 30px;
  }
  
  .forgotpassword-label{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
   
  }
  
  .forgot-text{
    font-family:'Poppins';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.47px;
    color: #f43254;
  }
  
  .forgotpassword-btn{
    /* changes */
    /* padding: 13px 130px 15px; */
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
    background-color: #f43254;
    border:none;
    color: white;
  }
  
  .forgotpassword-btn:hover{
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
    background-color: #ca2543;
    border:none;
    color: white;
  }
  
  .forgotpassword-area .forgotpassword-form form {
    padding-top: 15px;
    width: 360px;
  }
  
  .forgotpassword-area .forgotpassword-form form label {
    font-weight: 500;
  }
  
  .forgotpassword-area .forgotpassword-form form .form-control {
    background: transparent;
    font-size: 13px;
  }
  
  .forgotpassword-area .forgotpassword-form .btn {
    display: block;
    width: 100%;
    
  }
  
  .forgotpassword-area .forgotpassword-form p {
    overflow: hidden;
    margin-bottom: 0;
    margin-top: 20px;
  }
  
  .forgotpassword-area .forgotpassword-form p a {
    color: #0d1028;
    font-weight: 600;
  }
  
  .forgotpassword-area .forgotpassword-form p a:hover, .forgotpassword-area .forgotpassword-form p a:focus {
    color: #FF2D55;
  }
  
  .forgotpassword-area .forgotpassword-form p a.pull-left {
    float: left;
  }
  
  .forgotpassword-area .forgotpassword-form p a.pull-right {
    float: right;
  }