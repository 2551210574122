.filter-btn {
  padding: 13px 21px 13px 20px;
  border-radius: 4px;
  border: solid 1px #e9e9e9;
  background-color: #fff;
  color: black;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.newevent {
  padding-top: 30px;
}

.main-tab {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  margin-top: 50px;
}

.tab-button {
  padding: 18px 18px;
  border: solid 1px rgba(244, 50, 84, 0.12);
  background-color: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #888;
  border: solid 1px #e9e9e9
}


.tab-button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tab-button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}


.active-tab-button {
  background-color: #fff5f7;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #f43254;
  border-bottom: solid 4px #f43254;
  border-left: solid 1px #e9e9e9;
  border-top: solid 1px #e9e9e9;
  border-right: solid 1px #e9e9e9;
}

.active-tab-button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.active-tab-button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}


.submitbutton {
  padding: 13px 30px;
  border-radius: 8px;
  background-color: #f43254;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.submitbutton:hover {
  background-color: #f43254;
  color: white;
}

.preview-btn {
  color: #f43254;
}

.preview-btn:hover {
  color: #f43254;
}

/* ================================ */
/* Basic-Form */
/* =============================== */


.basicInfo {
  padding-bottom: 30px;
}

.basicinfo-head {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333;
}

.form-section-heading {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}

.basicinfo-p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333;
}

.Bannerimg-head {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}

.bannerimg-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  margin: 24px 0 0 1px;
  /* padding: 80px 131px; */
  border-radius: 5px;
  border: solid 1px #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
}


.upload-image-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addlink-btn {
  padding: 13px 30px;
  border-radius: 8px;
  background-color: #f43254;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.addlink-btn:hover {
  background-color: #f43254;
  color: white;
}


.uploadimg-font {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333;
}

.img-format-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #888;
}

.form-label {
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #818181;
}

.delete-event-btn {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #f43254;
}

.saveandcontinue-btn {
  padding: 13px 51px 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
  background-color: #f43254;
}

.react-time-picker__wrapper {
  width: 300px;
  padding: 10px;
  font-weight: 500;
  border: 1px solid #eeeeee;
  border-radius: 0;
  transition: 0.5s;
}

/* Packages Form */
/* =================================*/
.add-new-packages {
  padding: 13px 20px 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(244, 50, 84, 0.3);
  background-color: #f43254;
}

.sidemenu {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 20px;
  height: 710px;
  padding: 24px 20px;
  border-radius: 5px;
  border: solid 1px #e1e6d5;
  background-color: #fff;
  overflow: auto;
}

.select-pack-div {
  padding: 9px 17px 8px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  background-color: #f9f9f9;
}

.select-pack-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #888;
}

.form-heading {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}

.plus-div {
  padding: 9px;
  border-radius: 8px;
  background-color: #fff5f7;
}

.package-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}

.package-btn {
  border: none;
  padding: 0px 15px;
  border-radius: 0;
  transition: 0.5s;
  background-color: white;
}

.donation-input {
  position: relative;
}

.edit-btn {
  position: absolute;
  background-color: white;
  right: 60px;
  z-index: 10;
  color: black;
  border: none;
  margin-top: 3px;
  padding: 12px 5px;
  border-radius: 0;
  transition: 0.5s;
}

.delete-btn {
  position: absolute;
  background-color: white;
  right: 4px;
  z-index: 10;
  color: black;
  border: none;
  margin-top: 3px;
  padding: 12px 15px;
  border-radius: 0;
  transition: 0.5s;
}

.currency_tag {
  height: 50px;
  width: 50px;
  border: 1px solid green;
  position: absolute;
  top: 34px;
  right: 0px;
  background: url('../images/ic-rupee.png'), #edfff7;
  background-repeat: no-repeat;
  background-position: center;
}

.currency_price {
  /* mask-image: url('../images/ic-rupee@2x.png'); */

}

/* ==================================== */
/* pitch form */
/* ==================================== */

.Addlink-btn {
  /* width: 160px; */
  padding: 13px 30px;
  border-radius: 8px;
  background-color: #f43254;
}

.reactQuill {
  height: 180px;
}

.reactQuill-div2 {
  height: 300px;
}

.upload-icon {
  cursor: pointer;
}


.reactQuill-div {
  height: 300px;
}


